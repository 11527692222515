import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const baseUri = '/api/controltower/takeUpPurchaseContract'

const service = createCrudService(baseUri, api, {
  importLogUrl: '/api/controltower/importLog'
})

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.quantityUnit.options = ctx.quantityUnitOptions
  fieldsById.counterPartySector.options = ctx.counterPartySectorOptions
  fieldsById.pdCurrency.options = ctx.pdCurrencyOptions
  fieldsById.currency.options = ctx.currencyOptions
}

service.generateBooksById = async (id) => {
  try {
    const response = await api.put(
      `/api/controltower/takeUpPurchaseContract/generateBooks/${id}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
    return { error }
  }
}

service.generateBooksByFilter = async () => {
  try {
    const filter = _.isEmpty(service.lastAppliedFilter)
      ? ''
      : `?${service.lastAppliedFilter}`
    const response = await api.post(
      `/api/controltower/takeUpPurchaseContract/generateBooksByFilter${filter}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
    return { error }
  }
}

export default service
