<template>
  <div v-if="metadata != null">
    <custom-cg-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      :reloadData="reloadData"
      @onReloadData="reloadData = false"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import { CustomCgCrudMetaView, helpers } from '@cargill/shared'
import service from '../api/takeUpPurchaseContractService'

export default {
  components: { CustomCgCrudMetaView },
  data() {
    return {
      metadata: null,
      reloadData: false,
      crudService: service
    }
  },
  methods: {
    async getMeta() {
      const meta = await service.getMetaWithValidation()
      meta.actions = [
        {
          title: this.$t(
            'controlTower.pages.takeUpPurchaseContract.messages.generateBooks'
          ),
          className: 'fas fa-books',
          enable: () => {
            return true
          },
          callback: ({ node }) => {
            this.onGenerateBooksByIdHandler(node.data.id)
          }
        }
      ]
      meta.customToolbarActions = [
        {
          canShow: true,
          titleKey:
            'controlTower.pages.takeUpPurchaseContract.messages.generateBooks',
          onClick: this.onGenerateBooksHandler,
          iconColor: '#959DB5',
          faIcon: 'fa-books'
        }
      ]
      return meta
    },
    notifySucess() {
      this.notify.success({ title: this.$t('application.misc.success') })
    },
    notifyErrors(error) {
      const errors = error?.error?.response?.data?.errors ?? []
      errors.forEach((error) => {
        this.notify.error({
          title: this.$t(error)
        })
      })
    },
    hasErrors(response) {
      return response.error
    },
    async confirmGenerateBooksByFilterCompleted() {
      let confirm = await this.confirmGenerateBooksByFilter()
      if (confirm) {
        await new Promise((x) => setTimeout(x))
        confirm = await this.confirmGenerateBooksByFilterOnAllItems()
      }
      return confirm
    },
    async confirmGenerateBooksByFilter() {
      return helpers.createConfirmPromise(this, {
        title: this.$t('core.crud.attention'),
        subtitle: this.$t(
          'controlTower.pages.takeUpPurchaseContract.messages.confirmGenerateBooksByFilter'
        )
      })
    },
    async confirmGenerateBooksByFilterOnAllItems() {
      return helpers.createConfirmPromise(
        this,
        {
          type: 'error',
          title: this.$t('core.crud.attention'),
          subtitle: this.$t(
            'controlTower.pages.takeUpPurchaseContract.messages.confirmGenerateBooksByFilterOnAllItems'
          )
        },
        () => service.hasFilterApplied == null || service.hasFilterApplied()
      )
    },
    async onGenerateBooksHandler() {
      const confirm = await this.confirmGenerateBooksByFilterCompleted()
      if (confirm) {
        try {
          await this.generateBooksByFilter()
        } catch (err) {
          console.error(err)
          this.notify.error({
            title: this.$tc('core.misc.error')
          })
        }
      }
    },
    async generateBooksByFilter() {
      const resp = await service.generateBooksByFilter()
      if (this.hasErrors(resp)) {
        this.notifyErrors(resp)
      } else {
        this.notifySucess()
      }
      this.reloadData = true
    },
    async onGenerateBooksByIdHandler(id) {
      const confirm = await helpers.createConfirmPromise(this, {
        title: this.$t('core.crud.attention'),
        subtitle: this.$tc(
          'controlTower.pages.takeUpPurchaseContract.messages.confirmGenerateBooks'
        )
      })
      if (confirm) {
        try {
          await this.generateBooksById(id)
        } catch (err) {
          this.notifyErrors(err)
          console.error('Failed to change book status', err)
          this.notify.error({
            title: this.$tc('core.misc.error')
          })
        }
      }
    },
    async generateBooksById(id) {
      const resp = await service.generateBooksById(id)
      if (this.hasErrors(resp)) {
        this.notifyErrors(resp)
      } else {
        this.notifySucess()
      }
      this.reloadData = true
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
